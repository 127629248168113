/* App.css */

@font-face {
  font-family: 'Chopin Trial';
  src: url('assets/fonts/Chopin-Trial-ExtraLight-BF65b1d69203043.otf') format('truetype'); /* or use woff, woff2 */
  font-weight: 300;
  font-style: normal;
}

/* Import different weights if needed */
/* @font-face {
  font-family: 'Chopin Trial';
  src: url('assets/fonts/Chopin-Trial-Light-BF65b1d69151abf.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
} */

body {
  background-color: #fbfbfd;
  overflow: auto;
  text-align: center;
  color: #454545;
  height: auto;
  /* 100vh; */
  font-family: 'Chopin Trial', sans-serif;
  padding-bottom: 20px;
  background-color: #f6f6f6;
}

.App {
  text-align: center;
  position: relative;
  /* display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between; */
}

/* Style for the Navbar */
/* .navbar {
  background-color: #fff;
  color: #6e6e73;
  padding: 10px 0;
  height: 50px; */
/* } */

.navbar {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
  background-color: #d6dedf;
  
  /* color: #cccccc; */
  /* #2d4133; */
  /* #333; */
}

/* Style for the "ImmuLab" Text in the Navbar */
.navbar .logo {
  font-size: 24px;
  /* font-weight: bold; */
  /* color: #187C70; */
}

.navbar a {
  /* color: white; */
  color: #2d4133;
  text-decoration: none;
  font-weight: bold;
  padding-right: 0.125em;
}

.navbar a:hover {
  text-decoration: underline;
}

.blog {
  padding: 2rem;
}

/* Style for the main content area */
.content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  gap: 1vh;
}

.call-to-action-container,
.services-container,
.contact-form-container {
  align-items: center;
  justify-content: center;
  display: flex;
  /* margin: 1vh 0; */
  /* padding: 1vh; */ /*possibly change back*/
  /* border-radius: 5px; */
  flex: 1;
  /* background-color: #fff; */
  /* background-color: #fbfbfd; */
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
  /* border-radius: 20px; */
}

.services-container, .contact-form-container {
  display: flex;
  /* justify-content: space-between; */
  width: auto;
  align-items: flex-start;
}

@media (max-width: 1000px) {
  .services-container {
    justify-content: space-around;
  }
}

@media (max-width: 601px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .call-to-action img {
    width: 100%;
    height: auto;
  }

  .footer {
    position: static;
  }
}



@media (max-width: 500px) {
  .call-to-action {
    padding: 10px 10px 0px 10px;
    font-size: 12px;
  }

  .call-to-action img {
    width: 100%;
    height: auto;
    min-width: 80vw;
  }
}

.logo {
  height: 600px;
  /* width: 400px; */
  /* margin-bottom: 30px; */
  position: relative;
  /* top: 130px; */
  height: auto;
  max-height: 200px; /* Adjust as needed */
  width: auto;
  margin: 0 auto;
}

/* .logo {
  height: auto;
  max-height: 200px; /* Adjust as needed */
  /* width: auto;
  margin: 0 auto; */
/* }  */



img.logo {
  max-width: 500px; /* Set the width of the logo */
  max-height: 600px; /* Set the height of the logo */
  width: auto;  /* Auto-resize to maintain aspect ratio */
  height: auto; /* Auto-resize to maintain aspect ratio */
  margin: 0 auto; /* Center the logo */
  display: block; /* Make it a block element for centering */
}

/* Adjust the container size */
.call-to-action-container {
  height: 250px; /* Reduce the height of the container */
  display: flex;
  justify-content: center;
  align-items: center;
}









.call-to-action {
  color: #424245;
  font-size: 26px;
  /* font-weight: 300; */
  line-height: 1.5;
  letter-spacing: 1px;
}

.subtext-style {
  font-size: 14px;
}

/* Style for the Service Card (inside the Services Container) */
.service-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-width: 200px;
  text-align: center;
  /* font-weight: 300; */
  margin: 10px;
  color: #6e6e73;
  height: 200px;
  flex: 1; /* Each service card will take up an equal amount of space */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.contact-form-fixed {
  display: flex;
  flex-direction: column; 
  justify-content: flex-end; 
  height: 100%; 
  /* padding-bottom: 20px;  */
}

.contact-form form {
  display: flex;
  flex-direction: row;
  gap: 10px; 
  width: 100%; 
  justify-content: center; /* Centers form elements */
  align-items: center; /* Vertically aligns input and button */
  padding: 0; /* Removes any extra padding */
  margin: 0 auto; /* Centers the form horizontally */
}

@media (max-width: 600px) {
  .contact-form form {
    flex-direction: column;
  }
}

/* .contact-form input {
  padding: 10px;
  margin: 5px 0;
  width: 100%; 
  color: #6e6e73;
  border: 1px solid #6e6e73;
  border-radius: 20px;
} */
.contact-form input {
  padding: 20px;
  /* margin: 5px 0; */
  width: 100%; 
  color: #6e6e73;
  border: none; /* Removes the border */
  border-radius: 20px;
  background-color: #EEEEEE; /* Same background color as button */
  /*box-sizing: border-box; /* Ensure padding and borders are included in width calculation */
  flex: 1;
  z-index: 1;
  flex-grow: 1;
  position: relative;
}

.contact-form input, .contact-form button {
  padding: 20px;
  margin: 0; /* Removes any extra margins */
  border: none; /* Removes any borders */
  background-color: #EEEEEE; /* Matches background color */
  border-radius: 20px;
}

/* .contact-form button {
  padding: 10px;
  margin: 0;
  width: 50%; 
  margin-left: auto; 
  margin-right: auto; 
  color: #6e6e73;
  border-radius: 20px;
  border: none;
  background: #EEEEEE;
  font-style: bold;
} */
.contact-form button {
  padding: 20px;
  margin: 0;
  /* width: 100%;  */
  width: auto;
  margin-left: 0; 
  color: #6e6e73;
  border-radius: 20px;
  border: none;
  background: #EEEEEE; /* Same background color for consistency */
  font-style: bold;
  flex: 1;
  z-index: 1;
  /* box-sizing: border-box; /* Ensure padding and borders are included in width calculation */
}

.contact-form input:focus {
  outline: none; /* Removes the default blue outline */
  border: 1px solid #cccccc; /* Change to your desired color */
}

.contact-form {
  color: #6e6e73;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Chopin Trial', sans-serif;
  /* font-weight: 400; */
}


/* Style for the Contact Form Content (Centered within the Container) */
.contact-form-content {
  /* display: inline-block; */
  text-align: center;
  padding: 10px; /* Add padding around the form */
  max-width: 600px;
  margin: 0 auto; /* Center the content horizontally */
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Chopin Trial';
}

.contact-form-content {
  padding: 20px 0;
}

.logo {
  margin-bottom: 50px; /* Add space between the logo and the form */
}

.contact-form form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Add some gap between the input and submit button */
}



/* Style for the Company Copyright Notice */
.contact-form-content .copyright {
  text-align: center;
  padding: 15px; /* Add padding around the copyright */
  color: #6e6e73;
  font-weight: 200;
  font-size: 14px;
}

/* Style for the Footer */
.footer {
  background-color: #007bff;
  color: #1d1d1f;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  margin-top: 100px; 
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Chopin Trial';
}

.email-style {
  width: 50%;
}

.chip {
  color: #6e6e73;
  padding: 10px;
  border-radius: 20px;
  font-size: 12px;
  /* width: 40%; */
  text-align: center;
  font-weight: 200;
}

.copyright {
  /* align-self: center; */
  position: fixed;
  bottom: 0;
  width: 100%;
  /* text-align: center; */
  padding: 10px
}

.contact-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #6e6e73;
  color: #efefef; 
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Chopin Trial';
}

.contact-button:hover {
  background: #0062cc;
}

 